import * as React from "react"
import Link from 'components/Link'

const TeamGrid = props => {
	if (!props?.members?.length) return null;

	console.log(props.members);

	return (
		<section id="leadership">
			<div className="grid-container">
				<div className="grid-x small-up-1 medium-up-2">
					{props.members.map(({ photo, firstName, lastName, position, location, permalink }, index) => (
						<div key={`member-${index}`} className="cell member-wrapper">
							<Link to={`/about-us/${permalink}/`}>
								<div className="photo-wrapper" style={{ backgroundImage: `url('${photo}')` }}></div>
								<div className="content-wrapper">
									<h3>{firstName} {lastName}</h3>
									<span>{position}{location && ` | ${location}`}</span>
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default TeamGrid;