import * as React from "react";
import { graphql } from "gatsby";

import DataProvider from "providers/data";

import Layout from "templates/Layout";
import Content from "templates/Content";
import TeamGrid from "templates/team/TeamGrid";

import rainbow from "images/rainbow.png";
// import bgImage from "images/smile.png"

const TeamListing = (props) => {
	const { edges } = props.data.allContentPage;
	const { edges: peopleEdges } = props.data.allStaffPerson;
	const nodes = edges.map(({ node }) => node);
	let members = peopleEdges.map(({ node }) => node);
	let page = nodes[0];
	if (
		(props.providerResponse.data.length > 0 || !!members !== false) &&
		props.providerStatusText === "LOADED"
	) {
		members = props.providerResponse.data;
	}

	return (
		<Layout
			showContactForm={page?.showContactForm}
			showNewsletterSignUp={page?.showNewsletterSignUp}
			hoverboards={page?.hoverboards}
		>
			<Content gridSize={12} {...page} moveCommitmentsAboveContent={true} />
			<section id="meet-our-leadership-team">
				<div className="grid-container">
					<div className="grid-x grid-padding-x align-center">
						<div className="cell small-12">
							<div className="cell meet-the-team" >
								<h2>Meet Our Leadership Team</h2>
								<img src={rainbow} alt="rainbow" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<TeamGrid members={members} />
		</Layout>
	);
};

const getData = (props) => (
	<DataProvider
		liveRefresh={props.params.liveRefresh}
		apiParams={{ _sort: "sort", _order: "asc" }}
		type="StaffPerson"
	>
		<TeamListing {...props} />
	</DataProvider>
);
export default getData;

export const query = graphql`
	{
		allContentPage(
			filter: {
				uri: { eq: "/about-us/" }
				deleted: { eq: false }
				archived: { eq: false }
				approved: { eq: true }
			}
		) {
			edges {
				node {
					pageTitle
					meta
					content {
						main
					}
					showContactForm
					showNewsletterSignUp
					headlineBody
					headlineTitle
					headlineImage
					headlineType
					hoverboards
					commitment1Content
					commitment1Title
					commitment2Content
					commitment2Title
					commitment3Content
					commitment3Title
				}
			}
		}
		allStaffPerson(
			sort: { fields: sort, order: ASC }
			filter: {
				archived: { eq: false }
				approved: { eq: true }
				deleted: { eq: false }
			}
		) {
			edges {
				node {
					photo
					firstName
					lastName
					position
					location
					permalink
				}
			}
		}
	}
`;
